import React from 'react'
import './blogsearch.css'
const Blogsearch = ({value,handleSearchkey,clearSearch,formSubmit}) => {
  return (
    <div className='searchbar-wrap '>
        <form >
            <input 
            type="text" 
            placeholder='Search blog'
            onChange={handleSearchkey}
             value={value}/>
             {value && <span onClick={clearSearch}> x </span>}
             <button type='submit' onClick={(e)=>formSubmit(e)}>Search</button>
        </form>

    </div>
  )
}

export default Blogsearch