import { Link } from "gatsby";
import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import "./blog.css";
import Blogsearch from "./blogsearch";
import Footer from "../components/footer";
import Header from "../components/header";

const Blog = () => {
 

  const [blogs, setBlogs] = useState([]);
  console.log(blogs);
  const [blogdata, setBlogdata] = useState([]);
  const [allblogs, setAllblogs] = useState([]);
  const [searchkey, setSearchkey] = useState("");
 
  useEffect(() => {
    setAllblogs(blogdata);
  }, []);


useEffect(() => {
axios.get("http://localhost:8080/allblog")
.then((res) =>{
    setBlogdata(res.data);
}).catch(error =>{
    console.log(error);
});
 console.log(blogdata);
}, []);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleSearchResult();
  };

  const handleSearchResult = () => {
    const allBlogs = blogdata;
    const filteredBlogs = allBlogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchkey.toLowerCase().trim())
    );
    setBlogs(filteredBlogs);
  };

  const handleClearSearch = () => {
    setBlogs(blogdata);
    setSearchkey("");
  };

  const handleRedirect = (value) => {
  
    localStorage.setItem("blog", JSON.stringify(value))
     console.log(value)
  }
 

  return (
    <>
     <Header />
      <Blogsearch
        value={searchkey}
        formSubmit={handleSearchSubmit}
        clearSearch={handleClearSearch}
        handleSearchkey={(e) => setSearchkey(e.target.value)}
      />

      <div>
        <link
          rel="stylesheet"
          href="https://cdn.tailgrids.com/tailgrids-fallback.css"
        />
        <section className="pt-20 lg:pt-[120px] pb-10 lg:pb-20 bg-[#F3F4F6]">
          <div className="container">
            <div className="bloglist">
              {blogs.length === 0
                ? blogdata.map((value) => (

                    <div className="blogspace px-4">
                      <div className="bg-white rounded-lg overflow-hidden mb-10">
                        <img src={`http://localhost:8080/${value.image}`} alt="image" className="w-full" />

                        <div className="p-8 sm:p-9 md:p-7 xl:p-9">
                          <div className="grid author">
                            <div className="author-d  flex-initial">
                              {value.createdAt !== undefined ?
                              <>
                              {new Date(value.createdAt).toLocaleTimeString("en-US")}
                              &nbsp;&nbsp;|&nbsp;&nbsp; 
                              {new Date(value.createdAt).toLocaleDateString("en-US") }
                              </>
                              :null}
                            </div>
                          </div>
                          <div>
                            <h3>
                              <Link
                     onClick={()=>(handleRedirect(value))}
                                class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary" 
                        to='/blog-detail'
                              >
                                {value.title}
                              </Link>
                            </h3>
                          </div>
                          <p className="text-base text-body-color leading-relaxed mb-2">
                         
                           
                             {value.desc ? value.desc.slice(0,50) :null }
                           
                              
                          </p>
                          <div className="flex authview">
                            <Link
                              class="
                     blog-detail
                     border border-[#E5E7EB]
                     rounded-full
                      text-body-color
                     transition" 
                     to='/blog-detail'
                     onClick={()=>(handleRedirect(value))}
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  
                : (blogdata.length !== 0  ?
                   blogs.map((value) => (
                    <div className="blogspace px-4">
                      <div className="bg-white rounded-lg overflow-hidden mb-10">
                      <img src={`http://localhost:8080/${value.image}`} alt="image" className="w-full" />

                        <div className="p-8 sm:p-9 md:p-7 xl:p-9">
                          <div className="grid author">
                            <div className="author-d  flex-initial">
                            {value.createdAt !== undefined ?
                              <>
                              {new Date(value.createdAt).toLocaleTimeString("en-US")}
                              &nbsp;&nbsp;|&nbsp;&nbsp; 
                              {new Date(value.createdAt).toLocaleDateString("en-US") }
                              </>
                              :null}
                            </div>
                          </div>
                          <div>
                            <h3>
                              <Link
                              onClick={()=>(handleRedirect(value))}
                                class="
                    font-semibold
                    text-dark text-xl
                    sm:text-[22px]
                    md:text-xl
                    lg:text-[22px]
                    xl:text-xl
                    2xl:text-[22px]
                    mb-4
                    block
                    hover:text-primary
                    "     to='/blog-detail'
                              >
                                {value.title}
                              </Link>
                            </h3>
                          </div>
                          <p className="text-base text-body-color leading-relaxed mb-2">
                          {value.desc ? value.desc.slice(0,50) :null }
                          </p>
                          <div className="flex authview">
                            <Link
                          onClick={()=>(handleRedirect(value))}
                              class="
                 blog-detail
                 border border-[#E5E7EB]
                 rounded-full
                  text-body-color
                 transition"
                 to='/blog-detail'
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )):
                  "data not found")
                  }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
